import React, {useState} from "react"
import Styles from './testimonial.module.scss'
import classNames from 'classnames/bind'

let cx = classNames.bind(Styles);

const Testimonial = () => {

  const [throttle, setThrottle] = useState(false);
  const slides = 3;
  const [slide, setSlide] = useState(1);
  
  const next = () => {
    if(!throttle){
        setThrottle(true);
        const slider = document.querySelector('#slider');
        slider.style.transition = 'all 0.5s';
        slider.style.transform = 'translate(-33.33%)';
        setSlide(slide === slides ? 1 : slide + 1);
        setTimeout(function(){
            slider.style.transition = 'none';          
            setTimeout(() => {
              slider.appendChild(slider.firstElementChild);
              slider.style.transform = 'translate(0%)';
            },1);
        },500);
        setTimeout(function(){
            setThrottle(false);
        },600);
    }
    }

    const prev = () => {
    if(!throttle){
        setThrottle(true);
        const slider = document.querySelector('#slider');
        slider.prepend(slider.lastElementChild);
        slider.style.transform = 'translate(-33.33%)';
        setSlide(slide === 1 ? slides : slide - 1);
        setTimeout(function(){
          slider.style.transition = 'all 0.5s';
          slider.style.transform = 'translate(0%)';          
          setTimeout(() => {                
            slider.style.transition = 'none';    
          },500);
        },1);
        setTimeout(function(){
            setThrottle(false);
        },600);
    }
    }

  return (
  <>
    <div className={Styles.arrows}>
      <div className={cx(`left`,`data-hover`)} onClick={prev}>
        <svg width="36" height="36" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M33.25 21H8.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21 8.75L8.75 21L21 33.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div className={cx(`right`,`data-hover`)} onClick={next}>
        <svg width="36" height="36" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.75 21H33.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21 8.75L33.25 21L21 33.25" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div className={Styles.wrapper}>
      <div className={Styles.testimonials} id="slider">
        <div className={Styles.testimonial}>
          <h5 className={`med`}>It was a delightful and rewarding experience working with Creavo. Their approach to work and professionalism stood out from day one and I am very pleased with the final output, so much so that there have been a lot of people asking me who did this work for us!</h5>
          <p><i>&mdash; Alex Panels</i></p>
        </div>
        <div className={Styles.testimonial}>
          <h5 className={`med`}>I had a wonderful time working with team Creavo. The service exceeded my expectations in terms of aesthetics, timeline, and ideation. I love the final look of my website and cannot thank them enough to bring my dream to reality!</h5>
          <p><i>&mdash; Metamorphosis</i></p>
        </div>
        <div className={Styles.testimonial}>
          <h5 className={`med`}>We like working with our clients to understand their goals and helping them find solutions.</h5>
          <p><i>&mdash; Name 3, Position, Company</i></p>
        </div>
      </div>
    </div>
    <div className={Styles.steps}>
      <div className={cx(`step`, slide === 1 ? `active` : null)}></div>
      <div className={cx(`step`, slide === 2 ? `active` : null)}></div>
      <div className={cx(`step`, slide === 3 ? `active` : null)}></div>
    </div>
  </>
)}

export default Testimonial
