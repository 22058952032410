import React from 'react'
import Styles from './services.module.scss'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Services = () => {
    const data = useStaticQuery(graphql`
    query {
      web: file(relativePath: { eq: "web design.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      uxd: file(relativePath: { eq: "ux design.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      branding: file(relativePath: { eq: "branding.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      videography: file(relativePath: { eq: "video.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
    <div className={`split-wrapper margins-xl`}>
          <div className={`split-40`}>
            <Img className={`img-md`} fluid={data.web.childImageSharp.fluid} />
          </div>
          <div className={`split-60`}>
            <div className={`content`}>
              <h3>Web & App Development</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam neque, vitae fringilla amet, aliquam felis montes. Malesuada.</p>
            </div>
          </div>
        </div>
        <div className={`split-wrapper reverse margins-xl`}>
          <div className={`split-40`}>
            <Img className={`img-md`} fluid={data.uxd.childImageSharp.fluid} />
          </div>
          <div className={`split-60`}>
            <div className={`content`}>
              <h3>User Experience Design</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam neque, vitae fringilla amet, aliquam felis montes. Malesuada.</p>
            </div>
          </div>
        </div>
        <div className={`split-wrapper margins-xl`}>
          <div className={`split-40`}>
            <Img className={`img-md`} fluid={data.branding.childImageSharp.fluid} />
          </div>
          <div className={`split-60`}>
            <div className={`content`}>
              <h3>Branding & Strategy</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam neque, vitae fringilla amet, aliquam felis montes. Malesuada.</p>
            </div>
          </div>
        </div>
        <div className={`split-wrapper reverse margins-xl`}>
          <div className={`split-40`}>
            <Img className={`img-md`} fluid={data.videography.childImageSharp.fluid} />
          </div>
          <div className={`split-60`}>
            <div className={`content`}>
              <h3>Videography and Motion Graphics</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam neque, vitae fringilla amet, aliquam felis montes. Malesuada.</p>
            </div>
          </div>
        </div>
        </>
    )
}

export default Services

/* <div className={Styles.wrapper}>
        <div className={Styles.tile}>
            <div className={Styles.title}>
                <div className={Styles.icon}>
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 31.5H24" stroke="#EFA73C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 25.5V31.5" stroke="#EFA73C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30 4.5H6C4.34315 4.5 3 5.84315 3 7.5V22.5C3 24.1569 4.34315 25.5 6 25.5H30C31.6569 25.5 33 24.1569 33 22.5V7.5C33 5.84315 31.6569 4.5 30 4.5Z" stroke="#1E2E66" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                </div>
                <h5>Web & App Development</h5>
            </div>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam neque, vitae fringilla amet, aliquam felis montes. Malesuada.
            </p>
        </div>
        <div className={Styles.tile}>
            <div className={Styles.title}>
                <div className={Styles.icon}>                    
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.5 31.5V28.5C25.5 26.9087 24.8679 25.3826 23.7426 24.2574C22.6174 23.1321 21.0913 22.5 19.5 22.5H7.5C5.9087 22.5 4.38258 23.1321 3.25736 24.2574C2.13214 25.3826 1.5 26.9087 1.5 28.5V31.5" stroke="#1E2E66" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.5 16.5C16.8137 16.5 19.5 13.8137 19.5 10.5C19.5 7.18629 16.8137 4.5 13.5 4.5C10.1863 4.5 7.5 7.18629 7.5 10.5C7.5 13.8137 10.1863 16.5 13.5 16.5Z" stroke="#1E2E66" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.5 31.4999V28.4999C34.499 27.1705 34.0565 25.8791 33.242 24.8284C32.4276 23.7777 31.2872 23.0273 30 22.6949" stroke="#EFA73C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24 4.69495C25.2906 5.0254 26.4346 5.776 27.2515 6.82841C28.0684 7.88083 28.5118 9.17519 28.5118 10.5074C28.5118 11.8397 28.0684 13.1341 27.2515 14.1865C26.4346 15.2389 25.2906 15.9895 24 16.3199" stroke="#EFA73C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                </div>
                <h5>User Experience Design</h5>
            </div>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam neque, vitae fringilla amet, aliquam felis montes. Malesuada.
            </p>
        </div>
        <div className={Styles.tile}>
            <div className={Styles.title}>
                <div className={Styles.icon}>
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 28.5L28.5 18L33 22.5L22.5 33L18 28.5Z" stroke="#1E2E66" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M27 19.5L24.75 8.25L3 3L8.25 24.75L19.5 27L27 19.5Z" stroke="#1E2E66" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3 3L14.379 14.379" stroke="#EFA73C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.5 19.5C18.1569 19.5 19.5 18.1569 19.5 16.5C19.5 14.8431 18.1569 13.5 16.5 13.5C14.8431 13.5 13.5 14.8431 13.5 16.5C13.5 18.1569 14.8431 19.5 16.5 19.5Z" stroke="#EFA73C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                </div>
                <h5>Branding & Strategy</h5>
            </div>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam neque, vitae fringilla amet, aliquam felis montes. Malesuada.
            </p>
        </div>
        <div className={Styles.tile}>
            <div className={Styles.title}>
                <div className={Styles.icon}>
<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M34.5 28.5C34.5 29.2956 34.1839 30.0587 33.6213 30.6213C33.0587 31.1839 32.2956 31.5 31.5 31.5H4.5C3.70435 31.5 2.94129 31.1839 2.37868 30.6213C1.81607 30.0587 1.5 29.2956 1.5 28.5V12C1.5 11.2044 1.81607 10.4413 2.37868 9.87868C2.94129 9.31607 3.70435 9 4.5 9H10.5L13.5 4.5H22.5L25.5 9H31.5C32.2956 9 33.0587 9.31607 33.6213 9.87868C34.1839 10.4413 34.5 11.2044 34.5 12V28.5Z" stroke="#1E2E66" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 25.5C21.3137 25.5 24 22.8137 24 19.5C24 16.1863 21.3137 13.5 18 13.5C14.6863 13.5 12 16.1863 12 19.5C12 22.8137 14.6863 25.5 18 25.5Z" stroke="#EFA73C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                </div>
                <h5>Videography & Motion Graphics</h5>
            </div>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam neque, vitae fringilla amet, aliquam felis montes. Malesuada.
            </p>
        </div>
    </div> */