import React from 'react'
import Styles from './process.module.scss'
import classNames from 'classnames/bind'

let cx = classNames.bind(Styles);

const Process = () => {
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.col}>
        <div className={Styles.number}>
          <span>1</span>
        </div>
        <h3>Hello</h3>
        <p>Anim eu ex cupidatat aliquip labore mollit et aliqua nisi.</p>
      </div>
      <div className={Styles.col}>
        <div className={Styles.number}>
          <span>2</span>
        </div>
        <h3>Hello</h3>
        <p>Anim eu ex cupidatat aliquip labore mollit et aliqua nisi.</p>
      </div>
      <div className={Styles.col}>
        <div className={Styles.number}>
          <span>3</span>
        </div>
        <h3>Hello</h3>
        <p>Anim eu ex cupidatat aliquip labore mollit et aliqua nisi.</p>
      </div>
    </div>
  )
}

export default Process