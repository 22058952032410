import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Button from '../components/button/button'
import Testimonial from '../components/testimonial/testimonial'
import Services from '../components/services/services'
import SEO from "../components/seo"
import Process from "../components/process/process"
import { motion } from "framer-motion"

import image from '../images/about.jpg'

const AboutPage = () => {
  const transition = {
    type: "spring",
    mass: 0.35,
    stiffness: 75,
    duration: 0.3
  };

  return (
  <Layout>
    <SEO title="About" />
    <section id={`cover`} className={`primary header-fix lg`} style={{
        backgroundImage: `url(${image})`,
        backgroundSize: `cover`,
        backgroundPosition: `center center`,
        backgroundRepeat: `no-repeat`,
        boxSizing: `border-box`
      }}>
      <div className={`content--md`}>
        <motion.h3 className={`center thin`} initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={transition}>About Us</motion.h3>
        <motion.h1 className={`center`} initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 40 }}
          transition={transition}>What is Creavo?</motion.h1>
      </div>
    </section>
    <section>
      <div className={`content--sm`}>
        <h2>What we do</h2>
        <p className={`lg dark`}>Creavo helps you create elegant brand experiences with your customers. We craft digital products that combine technology, design, and business strategy to provide seamless and memorable interactions.</p>
      </div>
    </section>
    <section className={`dark`}>
      <div className={`content`}>
        <h2 className={`center`}>Our Process</h2>
        <Process />
      </div>
    </section>
    <section>
      <div className={`content--sm`}>
        <h2>Our Mission</h2>
        <p className={`lg dark`}>Our mission is to assist you in materializing your vision and to create pleasant experiences. We aim to achieve and maintain our industrial growth as a global tech service and consultancy.</p>
      </div>
    </section>
  </Layout>
) }

export default AboutPage
